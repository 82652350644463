import React, { useState } from "react"
import { EditBandFormTextField } from "./EditBandFormTextField"

export const Soundcloud = ({ band }) => {
  const [url, setUrl] = useState(band.soundcloud_url || "")

  const fieldData = {
    field: "soundcloud_url",
    serviceClass: "SoundcloudService",
    name: "soundcloud",
    label: "Your Soundcloud URL",
    placeholder: "Soundcloud URL",
    notes:
      "we no longer sync soundcloud releases but you can still link to your page. Your url should look like https://soundcloud.com/theblackblack",
    required: false,
  }

  return (
    <>
      <EditBandFormTextField
        fieldData={fieldData}
        value={url}
        setValue={setUrl}
        bandId={band.id}
      />
    </>
  )
}
