import React, { useState } from "react"
import { EditBandFormSelectField } from "./EditBandFormSelectField"

export const BandGenre = ({ band }) => {
  const [bandGenre, setBandGenre] = useState(band?.genre || "")

  const GENRE_OPTIONS = [
    {
      value: "classical",
      label: "Classical",
      description:
        "Encompasses orchestral music, chamber music, and operatic works.",
    },
    {
      value: "country",
      label: "Country",
      description:
        "Includes traditional country, modern country-pop, and bluegrass.",
    },
    {
      value: "electronic",
      label: "Electronic/Dance",
      description:
        "Encompasses electronic music, EDM, house, techno, and similar styles.",
    },
    {
      value: "folk",
      label: "Folk/Acoustic",
      description:
        "Includes traditional folk, modern folk, singer-songwriter, and acoustic music.",
    },
    {
      value: "gospel",
      label: "Gospel/Christian",
      description:
        "Includes gospel music, contemporary Christian music, and worship songs.",
    },
    {
      value: "hip_hop",
      label: "Hip-Hop/Rap",
      description:
        "Covers all forms of hip-hop and rap music, from old school to modern trap and mainstream rap.",
    },
    {
      value: "jazz_blues",
      label: "Jazz & Blues",
      description:
        "Combines jazz and blues, including classic jazz, swing, bebop, smooth jazz, and all forms of blues.",
    },
    {
      value: "latin",
      label: "Latin",
      description: "Covers salsa, bachata, reggaeton, and Latin pop music.",
    },
    {
      value: "pop",
      label: "Pop",
      description:
        "Mainstream pop music, bubblegum pop, and radio-friendly tunes.",
    },
    {
      value: "reggae",
      label: "Reggae",
      description: "Covers reggae, ska, and dancehall music.",
    },
    {
      value: "rnb_soul",
      label: "R&B/Soul",
      description: "Covers rhythm and blues, soul, funk, and contemporary R&B.",
    },
    {
      value: "rock",
      label: "Rock",
      description:
        "Includes all forms of rock music, including classic rock, alternative rock, metal, punk, hard rock, and pop-punk.",
    },
    {
      value: "soundtrack",
      label: "Soundtrack/Instrumental",
      description:
        "Covers movie/TV soundtracks, video game music, and instrumental compositions.",
    },
    {
      value: "world",
      label: "World Music",
      description:
        "Music rooted in non-Western traditions, including Afrobeat, K-pop, Bollywood, and regional genres.",
    },
  ]

  const fieldData = {
    field: "genre",
    name: "bandGenre",
    label: "Genre",
    placeholder: "What's your general genre?",
    required: false,
  }

  return (
    <EditBandFormSelectField
      value={bandGenre}
      setValue={setBandGenre}
      options={GENRE_OPTIONS}
      fieldData={fieldData}
      bandId={band.id}
      isArray={false}
    />
  )
}
